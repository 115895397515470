#footer-default {
  width: 100%;
  height: 100%;

  .copy-right {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    color: var(--gray);
    font-family: "Roboto Regular";
    font-weight: 400;
    font-size: 18px;
    align-content: center;
    text-align: center;

    p {
      margin: 16px 0;
    }

    .logos-container {
      margin-bottom: 24px;
      display: flex;
      align-items: center;

      [alt="Fortune Logo"] {
        width: auto;
        height: 30px;
      }
    }

    .social-medias {
      margin-top: 24px;
      display: flex;
      gap: 24px;

      a {
        width: 24px;
        height: 24px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          padding: 1px;
        }
      }
    }
  }

  @media only screen and (min-width: 45em) {
    display: grid;
    max-width: 76.5em;

    .contact-us-form-container {
      display: grid;
      gap: 24px;
      grid-template-columns: 1fr 1fr;

      // [alt="banner footer"] {
      //   width: 100%;
      //   height: 100%;
      // }

      .footer-default__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 600px;
        justify-self: center;
      }
    }

    .copy-right {
      //justify-content: space-between;

      .logos-container {
        margin-bottom: 24px;
        display: flex;
        align-items: center;

        span {
          margin: 0 12px;
          border-left: 1px solid var(--gray);
        }

        [alt="Fortune Logo"] {
          width: auto;
          height: 50px;
        }

        // [alt="Christie's Logo"] {
        //     margin-left: 8px;
        //     height: 32px;
        // }
      }
    }
  }

  @media only screen and (min-width: 64em) {
  }
}
