#business-card {
    display: grid;
    gap: 32px;

    img {
        border-radius: 8px;
        width: auto;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    .contact-info-container {
        .contact-info-container__table-tbody {
            display: grid;
            gap: 4px;
        }

        .table-key {
            padding-right: 8px;
            font-weight: 400;
        }

        .table-value {
            color: var(--gray);
            font-weight: 400;
        }
    }

    .contact__form {
        width: auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 16px;

        .grid-column-phone {
            display: grid;
            justify-content: space-between;
            grid-template-columns: 0.7fr 1fr;
            gap: 8px;
        }
    }

    .success-send-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 40px;
        // gap:16px;
        transition: all 0.3s ease-in-out;

        .success-send-form__title {
            font-weight: 400;
            font-size: 24px;
            text-align: center;
        }

        .success-send-form__details {
            font-weight: 400;
            font-size: 16px;
            text-align: center;
        }

        .success-send-form__icon {
            margin: 0 auto 24px auto;
            width: 24px;
            height: 24px;
        }
    }

    @media only screen and (min-width: 60em) {
        grid-template-columns: 200px auto 500px;
        grid-template-rows: auto;

        img {
            height: 100%;
        }

        .contact__form {
            width: 100%;
            grid-column: 3/4;
        }
    }
}
