#simple-select {
    &.disabled {
        background-color: #d4d4d4;
        cursor: inherit;
    }

    &.isWarning {
        outline: 1px solid var(--peach);
    }
}

#option-default {
    p {
        height: 48px;
        font-size: 14px;
    }
}
