#slide-carousel {
    position: relative;
    width: 100%;
    min-height: 400px;
    overflow: hidden;
    transition: all 0.4s ease-in-out;

    .carousel-button {
        position: absolute;
        z-index: 2;
        top: 40%;
        border: none;
        width: 100%;
        height: 80px;
        cursor: pointer;

        &.prev {
            left: 0;
            border-radius: 0 16px 16px 0;
            width: 50px;
            background: rgba(0, 0, 0, 0.25);

            [alt="arrow left"] {
                border-radius: 20px;
                width: 32px;
                padding: 8px;
                transition: 0.2s;
            }
        }

        &.next {
            right: 0;
            border-radius: 16px 0 0 16px;
            width: 50px;
            background: rgba(0, 0, 0, 0.25);

            [alt="arrow right"] {
                border-radius: 20px;
                width: 32px;
                padding: 8px;
                transform: rotate(180deg);
                transition: 0.2s;
            }
        }
    }

    #slide-carousel-images {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .main-slide-wrapper {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;

            .slide-carousel-slide {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0;
                transition: opacity 1s ease-in-out;

                &.active {
                    z-index: 1;
                    opacity: 1;
                }
            }

            // &::before {
                //   position: absolute;
                //   z-index: 3;
                //   width: 100%;
                //   height: 100%;
                //   content: "";
                //   background: linear-gradient(
                //     to top,
                //     rgb(0 0 0 / 5%) 100%,
                //     rgba(0, 0, 0, 0) 100%
                //   );
                //   pointer-events: none;
                // }
            }

            .footer-dots-button {
                position: absolute;
                z-index: 5;
                bottom: 0px;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding: 0 24px;

                .pagination-dots-wrapper {
                    display: flex;
                    justify-content: flex-end;
                    gap: 4px;

                    .pagination-dot {
                        margin: 24px 4px;
                        border: 4px solid;
                        border-radius: 4px;
                        width: 4px;
                        height: 4px;
                        transition: all 0.4s ease-in-out;
                        cursor: pointer;

                        &.current-dot {
                            width: 32px;
                        }
                    }
                }
            }
        }
    }
