#you-tube-frame {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    //min-height: 190px;

    .video-iframe {
        border-radius: 8px;
        width: 100%;
        height: 100%;
    }

    @media only screen and (max-width: 30em) {
        min-height: 300px;
        // Aumenta a altura mínima no mobile

        .video-iframe {
            min-height: 300px;
        }
    }

    @media only screen and (width >= 30em) {
        min-height: 550px;

        .video-iframe {
            min-height: 550px;
        }
    }

    @media only screen and (width >= 80em) {
        min-height: 688px;

        .video-iframe {
            min-height: 688px;
        }
    }
}
