#fade-carousel-images {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .fade-carousel-slide {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        opacity: 0;
        transition: opacity 2s ease-in-out;

        &.active {
            z-index: 1;
            opacity: 1;
        }

        .slide-background {
            position: relative;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
        }

        &::before {
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            content: "";
            background: linear-gradient(
            to top,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 0%,
            rgb(0 0 0 / 0%) 60%
            );
            pointer-events: none;
        }

        &::after {
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: "";
            pointer-events: none;
            background: rgba(0, 0, 0, 0.35);
        }
    }

    .property-info {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        width: 100%;
        padding: 0 24px;
        transform: translate(-50%, -50%);
        text-align: center;
        h1,

        h2 {
            color: var(--white);
        }

        .property-price {
            font-family: "Roboto Condensed Regular";
            font-weight: 500;
            font-size: 48px;
        }

        strong {
            font-weight: 900;
        }

        #button-default {
            display: inline-block;
            min-width: 243px;
        }
    }

    .footer-dots-button {
        position: absolute;
        z-index: 1;
        bottom: 24px;
        width: 100%;

        .pagination-dots-wrapper {
            margin: 0 40px;
            display: flex;
            justify-content: flex-end;
            gap: 4px;

            .pagination-dot {
                margin: 24px 4px;
                border: 4px solid;
                border-radius: 4px;
                width: 4px;
                height: 4px;
                transition: all 0.4s ease-in-out;

                &.current-dot {
                    margin: 24px 4px;
                    width: 32px;
                }
            }
        }
    }

    @media only screen and (max-width: 45em) {
        .property-info {
            padding: 0 24px;

            h1 {
                font-size: 1.5rem;
            }

            h2 {
                font-size: 0.8rem;
            }

            .property-price {
                font-size: 1.8rem;
            }

            #button-default {
                display: inline-block;
                min-width: 243px;
            }
        }
    }
}
