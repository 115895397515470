.home-list-details {
    margin-top: 20px;
    width: 100%;
    display: grid;
    // font-family: "Roboto Regular";
    // font-weight: 400;
    // font-size: 24px;
    grid-template-rows: auto auto auto;

    h3 {
        font-weight: 400;
        font-size: 24px;
    }

    .cards-specifications {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;

        .card {
            border-radius: 8px;
            width: calc(50% - 8px);
            height: 76px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--ghost);
            text-align: center;

            h5 {
                color: var(--gray);
                font-family: "Futura Book";
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            p {
                color: #028d9f;
            }
        }
    }

    .list-specifications {
        width: 100%;

        li {
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-bottom: 1px solid var(--ash);

            &:last-child {
                border-bottom: none;
            }

            .list-key {
                color: var(--gray);
                font-weight: 300;
                font-size: 14px;
                text-transform: uppercase;
            }

            .list-value {
                font-weight: 300;
                font-size: 14px;
            }
        }
    }

    .action-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        #button-default {
            min-width: 243px;
        }
    }

    @media only screen and (min-width: 64em) {
        margin-top: 0px;

        .action-buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 16px;

            // #button-default {
                //     width: 100%;
                // }
            }

            .cards-specifications {
                flex-wrap: nowrap;

                .card {
                    width: 100%;

                    h4 {
                        font-size: 16px;
                    }

                    p {
                        font-size: 22px;
                    }
                }
            }
        }
    }
