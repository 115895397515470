.landing-page {
    .main-info {
        margin-top: 80px;
        text-align: center;
    }

    @media only screen and (min-width: 26.563em) {
        .buttons-wrapper {
            flex-direction: row;
        }
    }

    @media only screen and (min-width: 64em) {
        .buttons-wrapper {
            #button-default {
                width: 400px;
            }
        }
    }
}
