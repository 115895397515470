#image-and-text-component {
  .image-and-text-component__slide {
    border-radius: 8px;
    width: 100%;
  }

  .image-and-text-component__text-wrapper {
    .text-wrapper__title {
      font-family: "Raleway Medium";
      font-weight: 500;
      font-size: 32px;
    }

    .text-wrapper__paragraph {
      font-family: "Roboto Regular";
      font-weight: 400;
      font-size: 18px;
    }

    .image-and-text-component__button-wrapper {
      [alt="icon button right"] {
        margin: 7px 9px;
        width: 7px;
        height: 10px;
      }
    }
  }
  .hor-photo-text,
  .hor-text-photo,
  .vert-photo-text,
  .vert-text-photo {
    display: flex;
    gap: 24px;
  }
  .hor-photo-text,
  .hor-text-photo {
    flex-direction: column;
  }
  .vert-photo-text,
  .vert-text-photo {
    flex-direction: column;
  }

  @media only screen and (min-width: 64em) {
    .hor-photo-text,
    .hor-text-photo {
      flex-direction: row;
      text-align: left;
    }

    .hor-text-photo {
      flex-direction: row-reverse;
    }

    .vert-photo-text {
      text-align: center;
    }

    .vert-text-photo {
      flex-direction: column-reverse;
      text-align: center;
    }

    .image-and-text-component__slide {
      height: 368px;
      min-width: 600px;

      &.gallery-height {
        height: 600px;
      }
    }
  }
}
