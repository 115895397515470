#text-area-default {
    //margin: 16px 0;

    textarea {
        border: 1px solid var(--ash);
        border-radius: 24px;
        width: 100%;
        background-color: var(--white);
        padding: 16px;
        color: var(--dawn);
        font-family: "Roboto Regular";
        font-size: 14px;
        //margin-top: 8px;
        min-height: 48px;
        resize: none;
        overflow: hidden;

        &::placeholder {
            color: var(--gray);
        }

        &.is-warning {
            outline: 1px solid var(--peach);
        }
    }

    label {
        padding-bottom: 8px;
        font-family: "Roboto Regular";
        font-size: 16px;
    }
}
